<template>
    <div class="facilityWrap">
        <div class="facilityBox">
          <div class="facilityTop" style="margin-bottom:10px">
            <el-button size="medium" @click="$router.go(-1)" icon="el-icon-arrow-left">返回</el-button>
          </div>
            <!-- 操作信息表单 -->
            <el-table :data="operatelist" :header-cell-style="{background:'#f0f0f0',color:'#666'}">
                <el-table-column type="index" align="center" fixed="left" label="操作次序" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EquipNO" fixed="left" label="设备编号" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EquipType" label="设备类型" width="110" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EquipStatus" label="设备工作状态" width="110" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="OwnerName" label="设备所有人" width="250" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="CarNumber" label="绑定车牌号" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="CarNumberType" label="牌照类型" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="CarType" label="车辆类型" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="Attribution" label="车辆归属类型" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="DriverN" label="绑定司机" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="OperateType" label="操作" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="OperatorName" label="操作人姓名" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="BindTime" label="操作时间" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="Remark" label="备注说明" width="280" show-overflow-tooltip></el-table-column>
            </el-table>
            <!-- 分页 -->
            <div style="margin-top:10px;">
                <el-pagination background 
                    class="pagination" 
                    @current-change="handleCurrentChange" 
                    :current-page.sync="pagination.page" 
                    :page-size="pagination.pagesize" 
                    layout="total, prev, pager, next, jumper"
                    :total="pagination.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {equipmentsInfo} from '@/api/transport/ioManage/index';
export default {
    data(){
        return{
            // 操作信息
            operatelist:[],
            // 请求数据参数
            infoData:{
                pageSize:10,
                pageIndex:1
            },
            facility:{},
            // 筛选条件数据
            pagination: { //分页控件相关参数
                page: 1, //当前所处的页码
                pagesize: 11, //每次请求的数量
                total: 0, //总条数
            },
        }
    },
    created(){
        this.infoData.EquipID = this.$route.query.EquipID;
        this.infoData.EquipTypeID = this.$route.query.EquipTypeID;
        this.facility.EquipNO = this.$route.query.EquipNO;
        this.facility.EquipStatus = this.$route.query.EquipStatus;
        this.facility.EquipType = this.$route.query.EquipType;
    },
    mounted(){
        this.equipmentsInfo();
    },
    methods:{
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.infoData.pageIndex = e;
            this.equipmentsInfo();
        },
        // 获取操作记录
        equipmentsInfo(){
            equipmentsInfo({Json:JSON.stringify(this.infoData)}).then(res=>{
                res.equipments.forEach((item,index,arr) => {
                    item.EquipNO = this.facility.EquipNO
                    item.EquipStatus = this.facility.EquipStatus
                    item.EquipType = this.facility.EquipType
                });
                this.operatelist = res.equipments;
                this.pagination.total = Number(res.totalRowCount)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.facilityWrap{
    .fromWrap{
        .operate{
            width: 20vw;
            margin: 0 0 30px 40%;
        }
    }
    .facilityTitlle{
        width: 100%;
        text-align: center;
        font-size: 26px;
        margin: 0 0 30px 0;
    }
}
</style>